import { Manager } from 'socket.io-client';
import Logger from './Logger.js';

const log = new Logger('Socket', 'debug', false);
const protocol = 'wss:'
const hostname = window.location.hostname;
const port = window.location.port;
const isStandardPort = (port === '80' || port === '443' || !port);
const url = isStandardPort 
  ? `${protocol}//${hostname}` 
  : `${protocol}//${hostname}:${port}`;


const manager = new Manager(url, {
  forceNew: false,
  rejectUnauthorized: false,
  path:'/io',
  transports: ['websocket'],
  reconnectionDelayMax: 10000,
});

const channels = {};
let timerReconnectEvent = undefined

function subscribe(channel) {

  const socket = manager.socket(channel);

  if (ENV === 'dev') {
    //log.debug(`${channel}: connecting`);

    socket.on('connect',              () =>       {
      log.debug(`${channel}: connected`, socket.id);

      const engine = socket.io.engine;
      //log.debug('engine', engine.transport.name); // in most cases, prints "polling"

      engine.once('upgrade', () => {
        // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
        log.debug('transport', engine.transport.name); // in most cases, prints "websocket"
      });

      /*
      engine.on('packet', ({ type, data }) => {
        log.debug('packet', type, data);
      });
      */

      engine.on('packetCreate', ({ type, data }) => {
        log.debug('packetCreate', type, data);
      });

      engine.on('drain', () => {
        log.debug('drain');
      });

      engine.on('close', (reason) => {
        log.debug('close', reason);
      });

    });
    //socket.on('disconnect',           () =>       { log.debug(`${channel}: disconnected`); });
    socket.io.on('reconnect',          attempt =>  {
      log.warn(`${channel}: reconnect ${attempt}`);

      // we can have multiple socket, but we want to send only one event
      clearTimeout(timerReconnectEvent);
      timerReconnectEvent = setTimeout(() => {
        window.dispatchEvent(new CustomEvent('socket-reconnected'));
      }, 100);

    });
    socket.io.on('error',              error =>    { log.error(`${channel}: ${error}`) });
    /*
    socket.io.on('reconnect_attempt',  attempt =>  { log.debug(`${channel}: reconnect_attempt ${attempt}`); });
    socket.io.on('reconnect_error',    error =>    { log.error(`${channel}: reconnect_error: `, error.message); });
    socket.io.on('reconnect_failed',   error =>    { log.error(`${channel}: reconnect_failed`, error); });
    socket.io.on('ping', () => { log.debug(`${channel}: ping`); });
    */
  }

  channels[channel] = socket;

  socket.onMessages = ({ room, event }, callback) => {
    socket.emit('joinRoom', room);
    socket.off('joinedRoom');
    socket.on('joinedRoom', () => {
      socket.off(event);
      socket.on(event, async data => { await callback(data); });
    });
  }

  socket.leave = ({ room, event }) => {
    socket.off(event);
    socket.emit('leaveRoom', room);
  }

  return socket;
}

function unsubscribe(channel) {
  if (channels[channel]) {
    channels[channel].close();
    delete channels[channel];
  }
}

export default {
  manager,
  subscribe,
  unsubscribe
}